/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars

export default (baseApi: string) => ({
  intervals: {
    logout: 3600, // 1 hour in seconds
  },
  api: {
    // Put your api calls here
    /**
     * Example
     * dashboard: `${baseApi}/api/v1/dashboards`,
     * schedule: `${baseApi}/api/v1/schedule`,
     */
  },
  token: {
    // Put your app tokens here
    /**
     * Example
     * auth: process.env.REACT_APP_AUTH_TOKEN,
     */
  },
  isProduction: true,
  isDevelopment: false,
});
