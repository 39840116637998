/* eslint-disable no-restricted-globals */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import environment from 'environment';
import './App.css';
import { getUserInfo } from './utils/fb';
import Success from './pages/Success';
import Loader from './assets/svg/loader.svg';

import sendMessageToExtension from './utils/chrome';

function App() {
  const [infoPageParams, setInfoPageParams] = React.useState({
    body: 'Facebook Login is in progress',
    heading: 'Please Wait',
    image: (<img src={Loader} className="w-16 h-16 mx-auto my-6 animate-spin" alt="NoDataFound" />) as any,
    type: undefined as any,
  });

  const loginSuccess = async () => {
    try {
      const userInfo: any = await getUserInfo(
        'email,ads_management,ads_read,read_insights,pages_show_list,instagram_basic,pages_read_engagement,pages_read_user_content,pages_manage_metadata,pages_manage_ads,public_profile,business_management',
        'name,email,picture,businesses,permissions',
      );
      setInfoPageParams({
        body: 'Please open Pixis chrome extension in order to proceed further',
        heading: 'Logged in Successfully',
        image: undefined,
        type: undefined,
      });
      await sendMessageToExtension({
        activity: 'fblogin',
        status: 'success',
        payload: userInfo,
      });
      setTimeout(() => {
        self.close();
      }, 3000);
    } catch (error) {
      setInfoPageParams({
        body: 'Try Facebook Login again',
        heading: 'Please try again',
        image: undefined,
        type: 'Info',
      });
    }
  };

  const paymentSuccess = async () => {
    await sendMessageToExtension({
      activity: 'paymentSuccess',
      status: 'success',
      payload: window.location.pathname.split('/')[3],
    });
    setTimeout(() => {
      self.close();
    }, 3000);
  };

  const paymentFailure = async () => {
    await sendMessageToExtension({
      activity: 'paymentFailure',
      status: 'failure',
      payload: window.location.pathname.split('/')[3],
    });
    setTimeout(() => {
      self.close();
    }, 3000);
  };

  React.useEffect(() => {
    (async () => {
      try {
        if (window.location.pathname.startsWith('/payment')) {
          if (window.location.pathname.includes('/success')) {
            await paymentSuccess();
          }
          if (window.location.pathname.includes('/failure')) {
            await paymentFailure();
          }
        } else if (!FB) {
          (window as any).fbAsyncInit = async () => {
            FB.init({
              appId: environment.FACEBOOK_APP_ID,
              autoLogAppEvents: true,
              xfbml: true,
              version: 'v13.0',
            });
            await loginSuccess();
          };
        } else {
          FB.init({
            appId: environment.FACEBOOK_APP_ID,
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v13.0',
          });
          await loginSuccess();
        }
      } catch (error) {
        try {
          await sendMessageToExtension({
            activity: 'fblogin',
            status: 'Error',
            payload: error,
          });
        } catch (error2) {
          setInfoPageParams({
            body: 'Something Went wrong',
            heading: 'Please Try Again',
            image: undefined,
            type: 'Error',
          });
        } finally {
          setInfoPageParams({
            body: 'Something Went wrong',
            heading: 'Please Try Again',
            image: undefined,
            type: 'Error',
          });
        }
      }
    })();
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <Success
              heading={infoPageParams.heading}
              body={infoPageParams.body}
              image={infoPageParams.image}
              type={infoPageParams.type}
            />
          }
        />
        <Route
          path="payment/success/:subscriptionId"
          element={
            <Success heading="Verify payment status" body="Go to extension to check your payment status" type="Info" />
          }
        />
        <Route
          path="payment/failure/:subscriptionId"
          element={
            <Success heading="Payment failed" body="Go to extension to check your payment status" type="Error" />
          }
        />
      </Routes>
    </div>
  );
}

export default App;
