import React from 'react';

interface ISuccessProps {
  image?: any;
  heading: string;
  body: string;
  type?: 'Info' | 'Success' | 'Error';
}

const getType: any = {
  Info: (
    <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" className="w-16 h-16 mx-auto my-6">
      <path fill="#2196f3" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z" />
      <path
        fill="#fff"
        d="M22 22h4v11h-4V22zM26.5 16.5c0 1.379-1.121 2.5-2.5 2.5s-2.5-1.121-2.5-2.5S22.621 14 24 14 26.5 15.121 26.5 16.5z"
      />
    </svg>
  ),
  Success: (
    <svg viewBox="0 0 24 24" className="w-16 h-16 mx-auto my-6 text-green-600">
      <path
        fill="currentColor"
        d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
      />
    </svg>
  ),
  Error: (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-16 h-16 mx-auto my-6" viewBox="0 0 24 24" fill="#dc2626">
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
        clipRule="evenodd"
      />
    </svg>
  ),
};

const Success: React.FunctionComponent<ISuccessProps> = ({ image, heading, body, type }) => (
  <div className="flex flex-col items-center justify-center h-screen">
    {image || (type && getType[type])}
    <div className="text-center">
      <h3 className="text-base font-semibold text-center text-gray-900 md:text-2xl">{heading}</h3>
      <p className="my-2 text-gray-600">{body}</p>
      {type === 'Success' && <p> Have a great day! </p>}
      {type === 'Error' && <p> Try again! </p>}
    </div>
  </div>
);

Success.defaultProps = {
  image: undefined,
  type: 'Success',
};

export default Success;
