// import environment from 'environment';

export default (message: any) =>
  new Promise((resolve, reject) => {
    try {
      chrome.runtime.sendMessage('jehfgdbkopllniknigdghcmfgjcohiab', message, (response) => {
        if (!response.ack) {
          reject(new Error('Not able to connect to the extension'));
        }
        resolve(true);
      });
    } catch (error) {
      reject(error);
    }
  });
