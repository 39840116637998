import environment from './base';
// Change the baseapi url in future
export const baseApi = 'some-random-url';
const env = environment(baseApi);
export default {
  ...env,
  isProduction: true,
  isDevelopment: false,
  EXTENSION_ID: 'jehfgdbkopllniknigdghcmfgjcohiab',
  FACEBOOK_APP_ID: '172416393583457',
};
