export const getMeInfo = (fields: string) =>
  new Promise((resolve, reject): any => {
    FB.api('/me', { fields }, (user: any) => {
      if (user.error) {
        reject(user.error);
      }
      resolve(user);
    });
  });

export const login = (scope: string, fields: string) =>
  new Promise((resolve, reject) => {
    try {
      FB.login(
        (response: any) => {
          if (response.authResponse) {
            (async () => {
              const user: any = await getMeInfo(fields);
              resolve({ ...user, ...response });
            })();
          } else {
            reject(new Error('Facebook Login error'));
          }
        },
        { scope },
      );
    } catch (error) {
      reject(error);
    }
  });

export const getUserInfo = (scope: string, fields: string) =>
  new Promise((resolve, reject) => {
    try {
      FB.getLoginStatus((response: any) => {
        if (response.status === 'connected') {
          (async () => {
            const user: any = await getMeInfo(fields);
            resolve({ ...user, ...response });
          })();
        } else if (response.status === 'not_authorized') {
          throw new Error('Facebook Authentication error');
        } else {
          // IIFE
          (async () => {
            try {
              const user = await login(scope, fields);
              resolve(user);
            } catch (error) {
              reject(error);
            }
          })();
        }
      });
    } catch (error) {
      reject(error);
    }
  });
